import classnames from 'classnames';
import React from 'react';

import { Button } from '../../../Button';
import { Col } from '../../../Col';
import { appConfig } from '../../../config';
import { AddToCartFormHelper } from '../../../form/AddToCartForm';
import { Select } from '../../../form/fields/Select';
import { FormGroup } from '../../../form/FormGroup';
import { P } from '../../../HTMLElement';
import { t } from '../../../i18n/i18n';
import { Image } from '../../../Image';
import { PriceBlockA } from '../../../PriceBlockA';
import { Row } from '../../../Row';
import { Table } from '../../../Table';
import { TableBody } from '../../../TableBody';
import { TableHead } from '../../../TableHead';
import { useSearch } from '../../../useSearch';
import { renderUI } from '../../../util';
import { AccountQuickReorderSubpageProps } from '../AccountQuickReorderSubpage';
import { AccountSubpage } from '../AccountSubpage';
import { Box } from './Box';

export const AccountQuickReorderSubpageA = (props: AccountQuickReorderSubpageProps) => {
  const { searchResult } = useSearch({
    defaultPageSize: 10,
    populate: {
      items: {
        skus: true,
        variantProducts: {
          skus: true,
        },
      },
    },
    withOrderedProducts: true,
  });

  return renderUI({
    bs5: (
      <AccountSubpage>
        <Box>
          <div className="quick-reorder">
            {searchResult && searchResult.itemsCount > 0 ? (
              <React.Fragment>
                <P className="products-counter">
                  {t('account.quickReorder.productCount', { count: searchResult.itemsCount })}
                </P>
                <Table className="quick-reorder-table">
                  <TableHead>
                    <tr>
                      <th colSpan={2}>{t('account.quickReorder.table.product')}</th>
                      <th>{t('account.quickReorder.table.quantity')}</th>
                    </tr>
                  </TableHead>
                  <TableBody>
                    {searchResult.items?.map((item) => (
                      <React.Fragment key={item.id}>
                        {item.skus?.map((sku, skuIndex) => (
                          <AddToCartFormHelper key={sku.id} preselectedSkuId={sku.id} product={item} scope="tile">
                            {({ fieldsHelper, image, priceHelper }) => (
                              <tr className={classnames({ 'first-sku': skuIndex === 0 })}>
                                <td>
                                  <Row>
                                    <Col>
                                      <div className="image-container">
                                        {skuIndex === 0 && (
                                          <Image {...image} aspectRatio={appConfig.productImageAspectRatio} fluid />
                                        )}
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="product-info-container">
                                        {skuIndex === 0 && <P>{item.name}</P>}
                                        {sku.name}
                                        <PriceBlockA priceHelper={priceHelper} />
                                      </div>
                                    </Col>
                                  </Row>
                                </td>
                                <td>
                                  <Row>
                                    <Col>
                                      <div className="quantity-container">
                                        <FormGroup
                                          {...fieldsHelper.quantity.formGroup}
                                          label={undefined}
                                          noMarginBottom
                                        >
                                          <Select
                                            {...fieldsHelper.quantity.select}
                                            aria-label={t('accessibility.select.quantity', { productName: item.name })}
                                            includeEmptyOption={false}
                                            placeholder={undefined}
                                          />
                                        </FormGroup>
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="add-to-cart-container">
                                        <Button {...fieldsHelper.buttons.submit} />
                                      </div>
                                    </Col>
                                  </Row>
                                </td>
                              </tr>
                            )}
                          </AddToCartFormHelper>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </React.Fragment>
            ) : (
              <P className="no-products">{t('account.quickReorder.noProducts')}</P>
            )}
          </div>
        </Box>
      </AccountSubpage>
    ),
  });
};
